<template>
  <!--begin::Page loader-->
  <template v-if="loaderType === 'spinner-message'">
    <div class="page-loader flex-column">
      <div class="lds-ring"><img src="media/logos/Logo-Symbole-couleur.svg"/><div></div><div></div><div></div><div></div></div>
    </div>
  </template>
  <template v-else-if="loaderType === 'spinner-logo'">
    <div class="page-loader flex-column">
      <img alt="Logo" class="max-h-75px" :src="logo" />

      <div class="d-flex align-items-center mt-5">
        <span class="spinner-border text-primary" role="status"></span>
        <span class="text-muted fs-6 fw-bold ms-5">{{$t("Chargement...")}}</span>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="page-loader">
      <span class="spinner-border text-primary" role="status">
        <span class="visually-hidden">{{$t("Chargement...")}}</span>
      </span>
    </div>
  </template>
  <!--end::Page Loader-->
</template>

<script lang="ts">
import { defineComponent, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  name: "Loader",
  props: {
    logo: String,
  },
  setup() {
    const store = useStore();
    const router = useRouter()
    const loaderType = computed(() => {
      return store.getters.layoutConfig("loader.type");
    });

    onMounted(() => {
      if (router.currentRoute.value.params.name) {
        const paramName = router.currentRoute.value.params.param as string
        const param = router.currentRoute.value.params.code
        console.log({name: router.currentRoute.value.params.name as string, params: {[paramName]: param}})
        router.push({name: router.currentRoute.value.params.name as string, params: {[paramName]: param}})
      }
    })

    return {
      loaderType,
    };
  },
});
</script>
