
import { defineComponent, onMounted, onUpdated, reactive, watch } from "vue";
import { ToggleComponent } from "@/assets/ts/components/_ToggleComponent";
import KTMenu from "@/layout/aside/Menu.vue";
import { asideTheme } from "@/core/helpers/config";
import store from "@/store";

export default defineComponent({
  name: "KTAside",
  components: {
    KTMenu,
  },
  props: {
    lightLogo: String,
    darkLogo: String,
  },
  setup() {
    const state = reactive({
      redirect: "#/" + store.getters.currentUser.con_redirect_auto
    })

    const setRedirect = (() => {
      if (store.getters.currentUser.con_redirect_auto && store.getters.currentUser.con_redirect_auto !== null) {
        state.redirect = "#/" + store.getters.currentUser.con_redirect_auto
        console.log(store.getters.currentUser.con_redirect_auto)
      }else{
        state.redirect = "#/stillboard/overview"
      }
    })

    watch(() => store.getters.currentUser.con_redirect_auto, () => {setRedirect()});

    onMounted(async () => {
      setRedirect()
      ToggleComponent.reinitialization();
    });

    onUpdated(async () => {
      ToggleComponent.bootstrap();
    });

    return {
      state,
      asideTheme,
    };
  },
});
