<template>
  <!--begin::Activities drawer-->
  <div
    id="kt_stillonstock"
    class="bg-white"
    data-kt-drawer="true"
    data-kt-drawer-name="activities"
    data-kt-drawer-activate="true"
    data-kt-drawer-overlay="true"
    data-kt-drawer-width="{default:'300px', 'lg': '100%'}"
    data-kt-drawer-direction="start"
    data-kt-drawer-toggle="#kt_stillonstock_toggle"
    data-kt-drawer-close="#kt_stillonstock_close"
  >
    <div class="card shadow-none" style="width: 100%">
      <!--begin::Header-->
      <div class="card-header" id="kt_stillonstock_header">
        <h3 class="card-title fw-bolder text-dark">StillOnStock</h3>

        <div class="card-toolbar">
          <button
            type="button"
            class="btn btn-sm btn-icon btn-active-light-primary me-n5"
            id="kt_stillonstock_close"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="media/icons/duotune/arrows/arr061.svg" />
            </span>
          </button>
        </div>
      </div>
      <!--end::Header-->

      <!--begin::Body-->
      <div class="card-body position-relative" id="kt_stillonstock_body">
        <!--begin::Content-->
        <!-- <iframe src="https://stillonstock.com/#/board/overview" style="width: 100%; height: 100%;"/>>-->
        <!--end::Content-->
      </div>
      <!--end::Body-->

    </div>
  </div>
  <!--end::Activities drawer-->
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "kt-activity-drawer",
  components: {
  },
});
</script>
