<template>
  <!--begin::Menu-->
  <div
    class="
      menu
      menu-sub
      menu-sub-dropdown
      menu-column
      menu-rounded
      menu-gray-600
      menu-state-bg-light-primary
      fw-bold
      py-4
      fs-6
      w-400px
    "
    data-kt-menu="true"
  >
    <!--begin::Menu item-->
    <div class="menu-item px-3">
      <div class="menu-content d-flex align-items-center px-3">
        <!--begin::Avatar-->
        <div class="symbol symbol-50px me-5">
          <div class="symbol-label fs-2 fw-bold text-primary">
            {{ state.initialContact }}
          </div>
        </div>
        <!--end::Avatar-->

        <!--begin::Username-->
        <div class="d-flex flex-column">
          <div class="fw-bolder d-flex align-items-center fs-5">
            {{ state.contact.con_prenom }} {{ state.contact.con_nom }}
            <span
              class="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2"
              >{{ state.role }}</span
            >
          </div>
          <a href="#" class="fw-bold text-muted text-hover-primary fs-7">{{
            state.contact.con_mail
          }}</a>
        </div>
        <!--end::Username-->
      </div>
    </div>
    <!--end::Menu item-->

    <!--begin::Menu separator-->
    <div class="separator my-2"></div>
    <!--end::Menu separator-->

    <!--begin::Menu item-->
    <div class="menu-item px-5 my-1">
      <router-link to="/profil" class="menu-link px-5">
        {{$t("Paramètres du compte")}}
      </router-link>
    </div>
    <!--end::Menu item-->

    <!--begin::Menu item-->
    <div v-if="state.role=='Administrateur'" class="menu-item px-5 my-1">
      <router-link to="/adminuser" class="menu-link px-5">
        {{$t("Paramètrer les utilisateurs")}}
      </router-link>
    </div>
    <!--end::Menu item-->

    <!--begin::Menu item-->
    <div class="menu-item px-5 switch-lang">
      <button :class="state.language === 'en' ? 'activated' : null " @click="changeLang()">
        <img src="media/flags/france.svg">
        <img src="media/flags/united-kingdom.svg">
      </button>
    </div>
    <!--end::Menu item-->

    <!--begin::Menu item-->
    <div class="menu-item px-5">
      <a @click="signOut()" class="menu-link px-5"> {{$t("Déconnexion")}} </a>
    </div>
    <!--end::Menu item-->
  </div>
  <!--end::Menu-->
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import mAxiosApi from "@/api";
import { useI18n } from "vue-i18n";
import * as moment from "moment";

export default defineComponent({
  name: "kt-user-menu",
  components: {},
  setup() {
    const router = useRouter();
    const store = useStore();
    const i18n = useI18n()
    const frNav = ["fr", "fr-FR", "fr-BE", "fr-CA", "fr-LU", "fr-CH", "fr-MC", "fr-MC"]

    const state = reactive({
      initialContact: "",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      contact: {} as any,
      role : store.getters.currentUser.con_role_web,
      language: getLanguage()
    });

    function varState() {
      if (!store.getters.isUserAuthenticated) return false;
      state.contact = store.getters.currentUser;
      state.initialContact =
        (state.contact.con_prenom ? state.contact.con_prenom.charAt(0) : "") +
        (state.contact.con_nom ? state.contact.con_nom.charAt(0) : "");
    }

    watch(
      () => store.getters.currentUser,
      function () {
        varState();
      }
    );

    if (!store.getters.isUserAuthenticated) {
      // router.push({ name: "sign-in" });
    } else {
      varState();
    }

    const signOut = () => {
      mAxiosApi.post("/logout");
      store
        .dispatch(Actions.LOGOUT)
        .then(() => router.push({ name: "sign-in" }));
    };

    const changeLang = () => {
      let newLang
      if (state.language === "fr") {
        newLang = "en"
      }else{
        newLang = "fr"
      }
      localStorage.setItem("language", newLang)
      i18n.locale.value = newLang
      moment.default.locale(newLang)
      setLanguageBack(newLang)
      state.language = newLang
    }

    function getLanguage() {
      if (localStorage.getItem("language")) {
        return localStorage.getItem("language")
      }else{
        if (frNav.indexOf(navigator.language) !== -1) {
          return "fr"
        }else{
          return "en"
        }
      }
    }

    async function setLanguageBack(language): Promise<any> {
      const response = await mAxiosApi.get("/setLanguage/" + language);
    }

    return {
      signOut,
      state,
      changeLang,
    };
  },
});
</script>

<style>
.switch-lang img{
  width: 1.5em;
  z-index: 1;
}

.switch-lang button{
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 8em;
  height: 70%;
  margin: 5px;
  padding: 1em;
  border: none;
  border-radius: var(--el-border-radius-base);
  background-color: var(--el-color-primary-light-8);
}

.switch-lang button::before{
  content: '';
  position: absolute;
  left: 5px;
  width: 40%;
  height: 80%;
  border-radius: var(--el-border-radius-base);
  background-color: white;
  transition: left .2s;
}

.switch-lang button.activated::before{
  left: calc(100% - 40% - 5px);
}

</style>
