
import { defineComponent, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  name: "Loader",
  props: {
    logo: String,
  },
  setup() {
    const store = useStore();
    const router = useRouter()
    const loaderType = computed(() => {
      return store.getters.layoutConfig("loader.type");
    });

    onMounted(() => {
      if (router.currentRoute.value.params.name) {
        const paramName = router.currentRoute.value.params.param as string
        const param = router.currentRoute.value.params.code
        console.log({name: router.currentRoute.value.params.name as string, params: {[paramName]: param}})
        router.push({name: router.currentRoute.value.params.name as string, params: {[paramName]: param}})
      }
    })

    return {
      loaderType,
    };
  },
});
